import {
    Alert,
    Box,
    Button,
    ButtonDropdown,
    ButtonDropdownProps,
    Header,
    Link,
    Modal,
    SpaceBetween,
    Table,
    Toggle,
} from '@cloudscape-design/components';
import { deviceManagerAPI, incidentManagerAPI } from 'api';
import { usePageLayoutContext } from 'components/common/layout';
import { API_URL_PATH_DM_DEVICE, API_URL_PATH_DM_GROUP, API_URL_PATH_DM_RULE, API_URL_PATH_IM_PLANS } from 'constants/urls';
import useFetch from 'hooks/useFetch';
import { useEffect, useMemo, useState } from 'react';
import CreateAlertModal from '../CreateAlertModal';
import { useCollection } from '@cloudscape-design/collection-hooks';
import { AlertRule, Asset, AssetAlertRuleStatus, DeviceGroup, EscalationPlan } from 'types/custom';
import { snakeCaseToNormalCase } from 'components/device-card/utils';
import useFetchWithReactQuery from 'hooks/useFetchWithReactQuery';
import { getThresholdsOverlaps, thresholdCodeToName } from 'utils';
import AlertDetailsModal from '../AlertDetailsModal';
import { secondsToRelative, relativeToText } from 'utils/relativeTime';
import { OptionDefinition } from '@cloudscape-design/components/internal/components/option/interfaces';
import CreateDeviceGroup from 'components/device-group-manager/CreateDeviceGroup';
import { useAlertRulesManagerContext } from 'providers/AlertRulesManagerProvider';
import DeleteModal from 'components/delete-modal';
import useMutationWithReactQuery from 'hooks/useMutationWithReactQuery';

type AlertsTableType = {
    selectedGroup?: DeviceGroup,
    selectedDevices: Asset[]
    filterByMeasurement?: string,
    filterByThreshold?: string,
    loading?: boolean,
    onRulesChange?: (rules: AlertRule[]) => void,
    measurements: string[];
    isOnDeviceManagerPage?: boolean
    isOnDashboardPage?: boolean
}

const AlertsTable = ({
    filterByMeasurement,
    filterByThreshold,
    loading: parentLoading,
    selectedDevices,
    onRulesChange,
    measurements,
    isOnDeviceManagerPage = false,
    isOnDashboardPage = false,
}: AlertsTableType) => {
    const { selectedGroup } = useAlertRulesManagerContext();
    const { setNotification } = usePageLayoutContext();
    const [selectedDeviceRules, setSelectedDeviceRules] = useState<AlertRule[]>([]);
    const [selectedGroupRule, setSelectedGroupRule] = useState<AlertRule[]>([]);
    const [deviceSpecificRules, setDeviceSpecificRules] = useState<AlertRule[]>([]);
    const [groupSpecificRules, setGroupSpecificRules] = useState<AlertRule[]>([]);
    const [thresholdsOverlaps, setThresholdsOverlaps] = useState<string[]>([]);

    const [showAlertDetailsModal, setShowAlertDetailsModal] = useState(false);
    const [showGroupAlertDetailsModal, setShowGroupAlertDetailsModal] = useState(false);
    const [showCreateAlertModal, setShowCreateAlertModal] = useState(false);
    const [showCreateDeviceGroupModal, setShowCreateDeviceGroupModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [disableAllToggles, setDisableAllToggles] = useState(false);
    const onDeleteDiscard = () => setShowDeleteModal(false);

    const canCreateGroupFromSelectedDevices = selectedDevices?.length > 1 && measurements.length > 0;
    const shouldDisplayCreateButton = isOnDeviceManagerPage || canCreateGroupFromSelectedDevices || selectedGroup;

    const [allEscalationPlans, setAllEscalationPlans] = useState<OptionDefinition[]>([]);

    const { items, collectionProps } = useCollection(deviceSpecificRules, {
        sorting: {},
    });

    const { items: groupSpecificItems, collectionProps: groupSpecificCollectionProps } = useCollection(groupSpecificRules, {
        sorting: {},
    });

    const { data: plansList, isFetching: plansFetching, refetch: plansRefetch } = useFetchWithReactQuery<{ items: EscalationPlan[] }>({
        axiosInstance: incidentManagerAPI,
        url: API_URL_PATH_IM_PLANS,
        key: 'escalation-plans',
    });

    useEffect(() => {
        if (plansList) setAllEscalationPlans([
            {
                label: 'None',
                value: '',
                description: 'No notifications',
            },
            ...plansList?.items?.map((plan) => ({
                label: plan.name,
                value: plan.name,
                disabled: !plan.active,
                disabledReason: 'Activate this plan to use it',
                description: plan.description,
            })) || [],
        ]);
    }, [plansList]);

    useEffect(() => {
        setSelectedDeviceRules([]);
    }, [selectedGroup]);

    const [alertFetchUrl, fetchKey, referringCombo] = useMemo(() => {
        let baseUrl = ''

        if (isOnDeviceManagerPage || isOnDashboardPage){
            baseUrl = `${API_URL_PATH_DM_DEVICE}/${selectedDevices![0].name}/rules`
        }
        else if(selectedGroup){
            baseUrl = `${API_URL_PATH_DM_GROUP}/${selectedGroup.groupId}/rules`
        }
       
        const queryParams = []
        if (filterByMeasurement) queryParams.push(`measure=${filterByMeasurement}`);
        if (filterByThreshold) queryParams.push(`thresholdCode=${filterByThreshold}`);
        if (!selectedGroup && isOnDeviceManagerPage) {
            queryParams.push
            ("includeFromGroups=true")
        }
    
        const deviceOrGroup = isOnDeviceManagerPage ? 'device' : 'group';
        const identificator = isOnDeviceManagerPage ? selectedDevices![0].name : selectedGroup?.groupId ?? 'no-group';

        const filterDescription = [filterByMeasurement && `measure:${filterByMeasurement}`, filterByThreshold && `threshold:${filterByThreshold}`]
            .filter(Boolean)
            .join('|') || 'no-filters';

        const finalUrl = `${baseUrl}${queryParams.length ? `?${queryParams.join('&')}` : ''}`;
        const key = `${deviceOrGroup}-${identificator}-${filterDescription}`;

        return [finalUrl, key, `${deviceOrGroup} ${identificator}`];
    }, [isOnDeviceManagerPage, selectedGroup, selectedDevices, filterByMeasurement, filterByThreshold]);

    const {
        data: rulesResponse,
        error: rulesError,
        isLoading: rulesLoading,
        isFetching: rulesFetching,
        status: rulesStatus,
        refetch: rulesRefetch
    } = useFetchWithReactQuery<AlertRule[]>({
        axiosInstance: deviceManagerAPI,
        url: alertFetchUrl,
        key: fetchKey,
        enabled: alertFetchUrl !== ''
    });

    const { mutate: deleteAlertRules, isPending: isDeletingBulk } = useMutationWithReactQuery<any, { alertRuleIds: string[] }>({
        url: `${API_URL_PATH_DM_RULE}`,
        method: 'DELETE',
        api: deviceManagerAPI,
        onSuccess: () => {
            rulesRefetch();
            setNotification([
                {
                    type: 'success',
                    content: `Deleted alert rule${selectedDeviceRules.length > 1 ? 's' : ''} for ${referringCombo} successfully`,
                },
            ]);
            setSelectedDeviceRules([]);
            setShowDeleteModal(false);
        },
        onError: (error) => {
            setNotification([
                {
                    type: 'error',
                    content: error.message || 'Bad request',
                },
            ]);
        }
    });

    const onDeleteConfirm = () => {
        const ruleIds = selectedDeviceRules
            .map((rule) => rule.id ? rule.id : '')
            .filter((ruleId) => ruleId !== '');
        deleteAlertRules({ alertRuleIds: ruleIds });
    };

    useEffect(() => {
        // For updating this, also ensures that toggles aren't being updated
        if (rulesStatus === 'success' && !disableAllToggles) {
            const allRulesIncludingNewlyCreatedRule = rulesResponse?.filter((rule) => rule.status !== AssetAlertRuleStatus.DELETED) || [];
            const groupRules = allRulesIncludingNewlyCreatedRule.filter(rule => rule.deviceGroupId)
            const deviceSpecificRules = allRulesIncludingNewlyCreatedRule.filter(rule => !rule.deviceGroupId)
            setGroupSpecificRules(groupRules);
            setDeviceSpecificRules(deviceSpecificRules);
            onRulesChange?.(allRulesIncludingNewlyCreatedRule);
            setThresholdsOverlaps(getThresholdsOverlaps(deviceSpecificRules));
        }

        if (alertFetchUrl === "") {
            setGroupSpecificRules([]);
            setDeviceSpecificRules([]);
            onRulesChange?.([]);
        }
    }, [rulesStatus, rulesResponse, alertFetchUrl]);

    const handleButtonDropdownClick = (event: CustomEvent<ButtonDropdownProps.ItemClickDetails>) => {
        event.preventDefault();

        if (event.detail.id === 'details') {
            setShowAlertDetailsModal(true);
        } else if (event.detail.id === 'create') {
            if (selectedDevices.length > 1) {
                setShowCreateDeviceGroupModal(true)
            }
            else {
                setShowCreateAlertModal(true)
            }
        } else if (event.detail.id === 'edit') {
            setShowCreateAlertModal(true);
        } else if (event.detail.id === 'delete') {
            setShowDeleteModal(true)
        }
    };

    const { fetchData: updateStatusForAlertRule } = useFetch(
        {
            axiosInstance: deviceManagerAPI,
            method: 'PATCH',
        },
        { manual: true }
    );

    const handleRuleToggle = async (rule: AlertRule, checked: boolean) => {
        rule.status = checked ? AssetAlertRuleStatus.ENABLED : AssetAlertRuleStatus.DISABLED;
        setDisableAllToggles(true);
        const response = await updateStatusForAlertRule(`${API_URL_PATH_DM_RULE}/${rule.id}/toggle`, {
            status: rule.status,
        } as Partial<AlertRule>);
        if (response && response.status >= 200 && response.status < 300) {
            setNotification([{
                type: 'success',
                content: `Updated alert rule status for ${referringCombo} successfully`,
            }]);
        } else {
            rule.status = checked ? AssetAlertRuleStatus.DISABLED : AssetAlertRuleStatus.ENABLED;
            setNotification([
                {
                    type: 'error',
                    content: response?.data?.message?.toString() || `Unexpected error occurred when updating alert rule status for ${referringCombo}`,
                },
            ]);
        }
        setDisableAllToggles(false);
        rulesRefetch();
    }

    const commonColumnDefinitions = [
        {
            id: 'threshold',
            header: 'Threshold',
            cell: (item: AlertRule) => item.threshold_value,
            sortingField: 'threshold_value',
        },
        {
            id: 'deadband',
            header: 'Deadband',
            cell: (item: AlertRule) => relativeToText(secondsToRelative(item.deadband_period), '') || (<i>Disabled</i>),
            sortingField: 'deadband_period',
        },
        {
            id: 'escalation-plan',
            header: 'Escalation Plan',
            cell: (item: AlertRule) => item.escalation_plan || (<i>None</i>),
            sortingField: 'escalation_plan',
        }
    ]
    
    if (rulesError) return <Alert type='error' header='Error loading alert rules'>{rulesError.toString()}</Alert>;

    return (
        <>
            <Table
                {...collectionProps}
                onSelectionChange={({ detail }) =>
                    setSelectedDeviceRules(detail.selectedItems)
                }
                variant='container'
                selectedItems={selectedDeviceRules}
                columnDefinitions={[
                    {
                        id: 'status',
                        header: 'Enabled',
                        cell: (item: AlertRule) => (<Toggle
                            checked={item.status === AssetAlertRuleStatus.ENABLED}
                            onChange={({ detail }) => handleRuleToggle(item, detail.checked)}
                            disabled={disableAllToggles} />),
                        sortingField: 'status',
                    },
                    {
                        id: 'measure',
                        header: 'Measure',
                        cell: (item: AlertRule) => (<Link onFollow={() => {
                            setSelectedDeviceRules([item]);
                            setShowAlertDetailsModal(true);
                        }}>{snakeCaseToNormalCase(item.measure)}</Link>),
                        sortingField: 'measure',
                    },
                    {
                        id: 'type',
                        header: 'Type',
                        cell: (item: AlertRule) => (<Link onFollow={() => {
                            setSelectedDeviceRules([item]);
                            setShowAlertDetailsModal(true);
                        }}>{thresholdCodeToName(item.threshold_code)}</Link>),
                        sortingField: 'threshold_code',
                    },
                    ...commonColumnDefinitions
                ]}
                items={!(isOnDeviceManagerPage || isOnDashboardPage) && groupSpecificItems || items}
                loadingText='Loading alert rules'
                loading={parentLoading || rulesLoading || rulesFetching || isDeletingBulk}
                selectionType='multi'
                stripedRows
                empty={
                    <Box textAlign='center' color='inherit'>
                        <b>No alert rules</b>
                        <Box
                            padding={{ bottom: 's' }}
                            variant='p'
                            color='inherit'
                        >
                            No alert rules to display.
                        </Box>
                        <Button onClick={() => {
                            if (selectedDevices.length > 1) {
                                setShowCreateDeviceGroupModal(true)
                            }
                            else {
                                setShowCreateAlertModal(true)
                            }
                        }
                        }
                            disabled={!shouldDisplayCreateButton}
                            disabledReason='Selected devices have no measurements'>
                            Create Alert Rule
                        </Button>
                    </Box>
                }
                header={
                    <Header
                        counter={
                            selectedDeviceRules?.length
                                ? `(${selectedDeviceRules.length}/${deviceSpecificRules.length})`
                                : `(${deviceSpecificRules.length})`
                        }
                        actions={
                            <SpaceBetween direction='horizontal' size='xs'>
                                <Button iconName='refresh' onClick={() => { rulesRefetch(); plansRefetch(); }} loading={rulesFetching || plansFetching} />
                                <ButtonDropdown
                                    items={[
                                        {
                                            text: 'Create',
                                            id: 'create',
                                            disabled: !shouldDisplayCreateButton,
                                        },
                                        {
                                            text: 'Details',
                                            id: 'details',
                                            disabled: !selectedDeviceRules.length,
                                        },
                                        {
                                            text: selectedDeviceRules.length > 1 ? 'Edit Bulk' : 'Edit',
                                            id: 'edit',
                                            disabled: !selectedDeviceRules.length,
                                        },
                                        {
                                            text: 'Delete',
                                            id: 'delete',
                                            disabled: !selectedDeviceRules.length,
                                        },
                                    ]}
                                    onItemClick={handleButtonDropdownClick}
                                >
                                    Actions
                                </ButtonDropdown>
                            </SpaceBetween>
                        }
                    >
                        Alert Rules
                    </Header>
                }
            />

            {showAlertDetailsModal && (
                <AlertDetailsModal
                    visible={showAlertDetailsModal}
                    setVisible={setShowAlertDetailsModal}
                    alertRule={selectedDeviceRules[0]}
                />
            )}
            {showCreateDeviceGroupModal &&
                <Modal
                    size='medium'
                    onDismiss={() => setShowCreateDeviceGroupModal(false)}
                    visible={showCreateDeviceGroupModal}>

                    <CreateDeviceGroup
                        setShowCreateDeviceGroupModal={setShowCreateDeviceGroupModal}
                        setShowCreateAlertModal={setShowCreateAlertModal}
                        isDeviceSelectDisabled={true}
                    />
                </Modal>
            }
            {thresholdsOverlaps.length > 0 && !rulesLoading && !isDeletingBulk && (
                <Box margin={{ vertical: "xs" }} textAlign="center" color="inherit">
                    <Alert type='warning' header='Thresholds overlap'>
                        The lowest value for high or high-high threshold is higher than the highest value for low or low-low threshold. Please adjust the thresholds to avoid unexpected results.
                    </Alert>
                </Box>)
            }

            {showCreateAlertModal && selectedDevices && (
                <CreateAlertModal
                    visible={showCreateAlertModal}
                    setVisible={setShowCreateAlertModal}
                    rulesRefetch={rulesRefetch}
                    escalationPlansList={allEscalationPlans}
                    group={selectedGroup}
                    device={selectedGroup ? undefined : selectedDevices[0]}
                    alertRules={selectedDeviceRules}
                    setAlertRules={setSelectedDeviceRules}
                    measurements={measurements}
                />
            )}
            {showDeleteModal && (
                <DeleteModal
                    visible={showDeleteModal}
                    onDiscard={onDeleteDiscard}
                    onDelete={onDeleteConfirm}
                    loading={isDeletingBulk}
                    itemName={selectedDeviceRules!?.map((item) => {
                        return `measure: '${item.measure}' with threashold '${item.threshold_value}'`;
                    })}
                    itemCount={selectedDeviceRules?.length}
                    moduleName='Alert'
                />
            )}

            {isOnDeviceManagerPage &&
             <Box margin={{ vertical: "xs" }}>
                <Table
                    {...groupSpecificCollectionProps}
                    variant='container'
                    columnDefinitions={[
                        {
                            id: 'deviceGroupId',
                            header: 'Device Group Id',
                            cell: (item: AlertRule) => item.deviceGroupId,
                            sortingField: 'deviceGroupId',
                        },
                        {
                            id: 'measure',
                            header: 'Measure',
                            cell: (item: AlertRule) => (<Link onFollow={() => {
                                setSelectedGroupRule([item]);
                                setShowGroupAlertDetailsModal(true);
                            }}>{snakeCaseToNormalCase(item.measure)}</Link>),
                            sortingField: 'measure',
                        },
                        {
                            id: 'type',
                            header: 'Type',
                            cell: (item: AlertRule) => (<Link onFollow={() => {
                                setSelectedGroupRule([item]);
                                setShowGroupAlertDetailsModal(true);
                            }}>{thresholdCodeToName(item.threshold_code)}</Link>),
                            sortingField: 'threshold_code',
                        },
                        ...commonColumnDefinitions
                    ]}
                    items={groupSpecificItems}
                    loadingText='Loading alert rules'
                    loading={parentLoading || rulesLoading}
                    stripedRows
                    empty={
                        <Box textAlign='center' color='inherit'>
                            <b>No inherited alert rules from groups</b>
                        </Box>
                    }
                    header={
                        <Header
                            counter={`(${groupSpecificRules.length.toString()})`}                     
                        >
                            Inherited Alerts from Groups
                        </Header>
                    }
                    /> 
                </Box>
            }
            {showGroupAlertDetailsModal && (
                <AlertDetailsModal
                    visible={showGroupAlertDetailsModal}
                    setVisible={setShowGroupAlertDetailsModal}
                    alertRule={selectedGroupRule[0]}
                />
            )}
        </>
    );
};

export default AlertsTable;
