import { OptionDefinition } from '@cloudscape-design/components/internal/components/option/interfaces';
import { useCollection } from '@cloudscape-design/collection-hooks';
import { EmptyState } from 'components/empty-state/EmptyState';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    CollectionPreferencesProps,
    CollectionPreferences,
    ButtonDropdown,
    SpaceBetween,
    TextFilter,
    Button,
    Header,
    Select,
    Table,
    TableProps,
    Link,
} from '@cloudscape-design/components';

import { useDeviceManagerContext } from 'pages/device-manager/DeviceListPage';
import { buttonDropdownItems } from './table-config';
import useMutationWithReactQuery from 'hooks/useMutationWithReactQuery';
import useFetchWithReactQuery from 'hooks/useFetchWithReactQuery';
import { usePageLayoutContext } from 'components/common/layout';
import { PaginationContent } from 'components/table-content';
import UpdateDeviceModal from '../UpdateDeviceModal';
import DisableModal from 'components/disable-modal';
import { Asset, DeviceState } from 'types/custom';
import DeleteModal from 'components/delete-modal';
import { deviceManagerAPI } from 'api';
import {
    API_URL_PATH_PHYSICAL_DEVICE_TYPES,
    API_URL_PATH_DEVICE_TYPES,
    API_URL_PATH_DM_DEVICE,
    URL_PATH_DEVICE_MANAGER_INSTALL_DEVICE,
} from 'constants/urls';
import { toTitleCase } from 'utils';
import DeviceStateBadge from 'components/device-state-badge/DeviceStateBadge';
import useAuth from 'hooks/useAuth';

type DeviceTableProps = {
    showActions?: boolean;
    selectionType?: TableProps.SelectionType;
}

const DeviceTable = ({
    showActions,
    selectionType,
}: DeviceTableProps) => {
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showDisableModal, setShowDisableModal] = useState(false);
    const [initialSelectionMade, setInitialSelectionMade] = useState(false);
    const [physicalDeviceTypeOptions, setPhysicalDeviceTypeOptions] = useState<OptionDefinition[]>([{ label: 'All', value: '' }]);
    const [deviceTypeOptions, setDeviceTypeOptions] = useState<OptionDefinition[]>([{ label: 'All', value: '' }]);
    const [deviceStateOptions, setDeviceStateOptions] = useState<OptionDefinition[]>([{ label: 'All', value: '' }]);
    const onDeleteDiscard = () => setShowDeleteModal(false);
    const [tablePreferences, setTablePreferences] = useState<CollectionPreferencesProps.Preferences>(localStorage.getItem('deviceTablePreferences') ? JSON.parse(localStorage.getItem('deviceTablePreferences')!) : {
        pageSize: 15,
        wrapLines: true,
        contentDisplay: [
            { id: "name", visible: true },
            { id: "friendlyName", visible: true },
            { id: "deviceState", visible: true },
            { id: "deviceTypeId", visible: true },
            { id: "lastUpdated", visible: true },
            { id: "physicalDeviceId", visible: false },
            { id: "description", visible: false },
            { id: "lookupId", visible: false },
            { id: "invoiceId", visible: false },
            { id: "friendlyCurrentPath", visible: false },
        ],
        stickyColumns: { first: 0 },
    });

    const { roles } = useAuth();

    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const onUpdateDiscard = () => setShowUpdateModal(false);

    const navigate = useNavigate();
    const { setNotification } = usePageLayoutContext();

    const { data: deviceTypesData } = useFetchWithReactQuery({
        axiosInstance: deviceManagerAPI,
        key: 'device-types',
        url: `${API_URL_PATH_DEVICE_TYPES}`,
    });

    const { data: physicalDeviceTypesData } = useFetchWithReactQuery({
        axiosInstance: deviceManagerAPI,
        key: 'physical-device-types',
        url: `${API_URL_PATH_PHYSICAL_DEVICE_TYPES}`,
    });

    useEffect(() => {
        if (deviceTypesData) {
            setDeviceTypeOptions([
                { label: 'All', value: '' },
                ...deviceTypesData.map((deviceType: string) =>
                    ({ label: deviceType, value: deviceType }))
            ]);
        }
        if (physicalDeviceTypesData) {
            setPhysicalDeviceTypeOptions([
                { label: 'All', value: '' },
                ...physicalDeviceTypesData.map((physicalDeviceType: string) =>
                    ({ label: physicalDeviceType, value: physicalDeviceType }))
            ]);
        }
        setDeviceStateOptions([
            { label: 'All', value: '' },
            ...Object.keys(DeviceState).map((deviceState: string) =>
                ({ label: toTitleCase(deviceState), value: deviceState }))
        ]);
    }, [deviceTypesData, physicalDeviceTypesData]);

    const [selectedDeviceType, setSelectedDeviceType] = useState<OptionDefinition>({ label: 'All', value: '' });
    const [selectedPhysicalDeviceType, setSelectedPhysicalDeviceType] = useState<OptionDefinition>({ label: 'All', value: '' });
    const [selectedDeviceState, setSelectedDeviceState] = useState<OptionDefinition>({ label: 'All', value: '' });

    const {
        allDevices,
        setSelectedDevices,
        selectedDevices,
        getAllDevices,
        deviceFetching,
        deviceLoading,
        syncDevices,
        isSyncingDevices,
    } = useDeviceManagerContext();

    const filteredDevices: Asset[] = useMemo(() => {
        return allDevices.filter((device) => {
            const deviceTypeMatch = selectedDeviceType.value === '' || device.deviceTypeId === selectedDeviceType.value;
            const physicalDeviceTypeMatch = selectedPhysicalDeviceType.value === '' || device.physicalDeviceId === selectedPhysicalDeviceType.value;
            const deviceStateMatch = selectedDeviceState.value === '' || device.deviceState === selectedDeviceState.value;

            return deviceTypeMatch && physicalDeviceTypeMatch && deviceStateMatch;
        });
    }, [allDevices, selectedDeviceState, selectedDeviceType, selectedPhysicalDeviceType])

    const {
        items,
        actions,
        collectionProps,
        filterProps,
        paginationProps,
    } = useCollection(filteredDevices, {
        filtering: {
            empty: (
                <EmptyState
                    title="No devices"
                    subtitle="No devices to display."
                />
            ),
            noMatch: (
                <EmptyState
                    title='No matches'
                    subtitle='We can’t find a match.'
                    action={
                        <Button onClick={() => actions.setFiltering('')}>
                            Clear filter
                        </Button>
                    }
                />
            ),
        },
        pagination: { pageSize: tablePreferences.pageSize },
        sorting: {},
    });

    const { mutate: mutateDisableDevices, isPending: isDisableDevicesPending } = useMutationWithReactQuery<string[], {
        ids: string[]
    }>({
        url: `${API_URL_PATH_DM_DEVICE}/disable`,
        method: 'PATCH',
        api: deviceManagerAPI,
        onSuccess: ({ data }) => {
            getAllDevices();
            setNotification([
                {
                    type: 'success',
                    content: `Disabled ${data.length} devices successfully`,
                },
            ]);
            setSelectedDevices([]);
        },
        onError: (error) => {
            setNotification([
                {
                    type: 'error',
                    content: error.message || 'Error disabling devices',
                },
            ]);
        }
    });

    const {
        mutate: deleteDevice,
        error: deleteDeviceError,
        status: deleteDeviceStatus,
        isPending: isDeleteDevicesPending,
    } = useMutationWithReactQuery<void, void>({
        api: deviceManagerAPI,
        method: 'DELETE',
        url: `${API_URL_PATH_DM_DEVICE}/${selectedDevices?.[0]?.name || ''}`,
    });

    useEffect(() => {
        setSelectedDevices([]);
        getAllDevices();
        if (deleteDeviceStatus === 'success') {
            setNotification([{
                type: 'success',
                content: `Deleted device ${selectedDevices![0]?.name} successfully`,
            }]);
        } else if (deleteDeviceError) {
            setShowDeleteModal(false);
            setNotification([{
                type: 'error',
                content: String(deleteDeviceError) || 'Error deleting device',
            }]);
        }
    }, [deleteDeviceError, deleteDeviceStatus]);

    const onDeleteConfirm = () => {
        deleteDevice(undefined);
        setShowDeleteModal(false);
    };

    const handleButtonDropdownClick = (event: any) => {
        event.preventDefault();

        if (event.detail.id === 'disable') {
            setShowDisableModal(true);
        } else if (event.detail.id === 'delete') {
            setShowDeleteModal(true);
        } else if (event.detail.id === 'edit') {
            setShowUpdateModal(true);
        } else if (event.detail.id === 'install') {
            navigate(
                URL_PATH_DEVICE_MANAGER_INSTALL_DEVICE,
                {
                    state: {
                        flowType: 'install',
                        selectedDevices: selectedDevices.map(({ name, friendlyName }) => ({ name, friendlyName })),
                    },
                }
            );
        }
    };

    const onDisableConfirm = () => {
        const ids = selectedDevices!.map((device) => device.name);
        mutateDisableDevices({ ids });
        setShowDisableModal(false);
    };

    const refreshTable = async () => {
        const response = await getAllDevices();
        // This ensures that the details panel doesn't show old data
        if (response?.data) setSelectedDevices(response?.data?.items?.filter((item) => selectedDevices.map((device) => device.name).includes(item.name)) || []);
    }

    return (
        <>
            <Table
                {...collectionProps}
                onSelectionChange={({ detail }) => {
                    setSelectedDevices(detail.selectedItems);
                    if (!initialSelectionMade) {
                        setInitialSelectionMade(true);
                    }
                }}
                selectedItems={selectedDevices}
                loading={
                    deviceLoading ||
                    isDisableDevicesPending ||
                    isDeleteDevicesPending
                }
                wrapLines={tablePreferences.wrapLines}
                stickyHeader
                contentDensity={tablePreferences.contentDensity}
                stripedRows
                columnDefinitions={[
                    {
                        id: 'name',
                        header: 'Name',
                        cell: (item: Asset) => (<Link onFollow={() => { setSelectedDevices([item]) }}>
                            {item.name || '-'}
                        </Link>),
                        sortingField: 'name',
                    },
                    {
                        id: 'friendlyName',
                        header: 'Friendly Name',
                        cell: (item: Asset) => (<Link onFollow={() => { setSelectedDevices([item]) }}>
                            {item.friendlyName || '-'}
                        </Link>),
                        sortingField: 'friendlyName',
                    },
                    {
                        id: 'deviceState',
                        header: 'State',
                        cell: (item: Asset) => (<DeviceStateBadge state={item.deviceState} />),
                        sortingField: 'deviceState',
                    },
                    {
                        id: 'deviceTypeId',
                        header: 'Device Type',
                        cell: (item: Asset) => item.deviceTypeId,
                        sortingField: 'deviceTypeId',
                    },
                    {
                        id: 'physicalDeviceId',
                        header: 'Physical Device ID',
                        cell: (item: Asset) => item.physicalDeviceId,
                        sortingField: 'physicalDeviceId',
                    },
                    {
                        id: 'description',
                        header: 'Description',
                        cell: (item: Asset) => item.description,
                        sortingField: 'description',
                    },
                    {
                        id: 'lookupId',
                        header: 'Lookup ID',
                        cell: (item: Asset) => item.lookupId,
                        sortingField: 'lookupId',
                    },
                    {
                        id: 'invoiceId',
                        header: 'Invoice ID',
                        cell: (item: Asset) => item.invoiceId,
                        sortingField: 'invoiceId',
                    },
                    {
                        id: 'friendlyCurrentPath',
                        header: 'Friendly Current Path',
                        cell: (item: Asset) => item.friendlyCurrentPath,
                        sortingField: 'friendlyCurrentPath',
                    },
                    {
                        id: 'lastUpdated',
                        header: 'Last Updated',
                        cell: (item: Asset) => {
                            const date = new Date(item.updatedAt * 1000).toLocaleString();
                            return date === 'Invalid Date' ? 'Not recorded' : date;
                        },
                        sortingField: 'lastUpdated',
                    },
                ]}
                columnDisplay={tablePreferences.contentDisplay}
                stickyColumns={tablePreferences.stickyColumns}
                items={items}
                variant='container'
                loadingText='Loading resources'
                selectionType={selectionType}
                trackBy={(item) => item.name}
                filter={
                    <SpaceBetween size='xs' direction='horizontal' alignItems='end'>
                        <div style={{ width: '300px' }}>
                            <TextFilter
                                {...filterProps}
                                filteringPlaceholder='Find by friendly name and location'
                            />
                        </div>
                        <div style={{ minWidth: '100px' }}>
                            <Select
                                inlineLabelText='Device Type'
                                options={deviceTypeOptions}
                                selectedOption={selectedDeviceType}
                                onChange={(event) => { setSelectedDeviceType(event.detail.selectedOption) }}
                            />
                        </div>
                        <div style={{ minWidth: '130px' }}>
                            <Select
                                inlineLabelText='Physical Device ID'
                                options={physicalDeviceTypeOptions}
                                selectedOption={selectedPhysicalDeviceType}
                                onChange={(event) => setSelectedPhysicalDeviceType(event.detail.selectedOption)}
                            />
                        </div>
                        <div>
                            <Select
                                inlineLabelText='State'
                                options={deviceStateOptions}
                                selectedOption={selectedDeviceState}
                                onChange={(event) => setSelectedDeviceState(event.detail.selectedOption)}
                            />
                        </div>
                    </SpaceBetween>
                }
                header={
                    <Header
                        counter={
                            selectedDevices?.length && selectedDevices[0]?.name
                                ? '(' +
                                selectedDevices.length +
                                `/${allDevices && allDevices.length})`
                                : `(${allDevices && allDevices.length})`
                        }
                        actions={
                            showActions ? <SpaceBetween direction='horizontal' size='xs'>
                                <Button iconName='refresh' onClick={refreshTable}
                                    loading={deviceFetching} disabled={isSyncingDevices} />
                                {(roles as string[])?.includes('claim:sensors') && <Button onClick={async () => {
                                    await syncDevices();
                                    await refreshTable();
                                }} loading={isSyncingDevices}>Sync</Button>}
                                <ButtonDropdown
                                    items={buttonDropdownItems(selectedDevices)}
                                    onItemClick={handleButtonDropdownClick}
                                >
                                    Actions
                                </ButtonDropdown>
                            </SpaceBetween> : (<></>)
                        }
                    >
                        Devices
                    </Header>
                }
                preferences={
                    <CollectionPreferences
                        title='Table Preferences'
                        confirmLabel='Apply'
                        cancelLabel="Cancel"
                        preferences={tablePreferences}
                        wrapLinesPreference={{
                            label: 'Wrap lines',
                            description: 'Select to see all the text and wrap the lines',
                        }}
                        contentDensityPreference={{
                            label: 'Compact mode',
                            description: 'Select to display content in a denser, more compact mode',
                        }}
                        pageSizePreference={{
                            title: "Items per page",
                            options: [
                                { value: 15, label: "15 devices" },
                                { value: 30, label: "30 devices" },
                                { value: 50, label: "50 devices" },
                                { value: 100, label: "100 devices" },
                            ]
                        }}
                        contentDisplayPreference={{
                            options: [
                                { id: "name", label: "Name" },
                                { id: "friendlyName", label: "Friendly Name" },
                                { id: "deviceState", label: "Device State" },
                                { id: "deviceTypeId", label: "Device Type" },
                                { id: "lastUpdated", label: "Last Updated" },
                                { id: "physicalDeviceId", label: "Physical Device ID" },
                                { id: "description", label: "Description" },
                                { id: "lookupId", label: "Lookup ID" },
                                { id: "invoiceId", label: "Invoice ID" },
                                { id: "friendlyCurrentPath", label: "Friendly Current Path" },
                            ],
                            title: "Visible columns",
                            description: "Select columns to display in the table"
                        }}
                        stickyColumnsPreference={{
                            firstColumns: {
                                title: "Stick first column(s)",
                                description:
                                    "Keep the first column(s) visible while horizontally scrolling the table content.",
                                options: [
                                    { label: "None", value: 0 },
                                    { label: "First column", value: 1 },
                                    { label: "First two columns", value: 2 }
                                ]
                            }
                        }}
                        onConfirm={({ detail }) => {
                            setTablePreferences(detail);
                            localStorage.setItem('deviceTablePreferences', JSON.stringify(detail));
                        }}
                    />
                }
                pagination={
                    <PaginationContent paginationProps={paginationProps} />
                }
            />

            {showUpdateModal && (
                <UpdateDeviceModal
                    selectedDevice={selectedDevices}
                    visible={showUpdateModal}
                    onDiscard={onUpdateDiscard}
                    refetch={getAllDevices}
                />
            )}

            <DisableModal
                visible={showDisableModal}
                onDiscard={() => setShowDisableModal(false)}
                onDisable={onDisableConfirm}
                itemName={selectedDevices}
                moduleName='Device'
            />

            <DeleteModal
                visible={showDeleteModal}
                onDiscard={onDeleteDiscard}
                onDelete={onDeleteConfirm}
                itemName={selectedDevices}
                itemCount={selectedDevices?.length}
                moduleName='Device'
            />
        </>
    );
};

export default DeviceTable;
