import { Link } from "@cloudscape-design/components";
import DeviceStateBadge from "components/device-state-badge/DeviceStateBadge";
import { Asset, DeviceState } from "types/custom";

export const COLUMN_DEFINITIONS = [
    {
        id: 'name',
        header: 'Name',
        cell: (item: Asset) => (<Link onFollow={() => {}}>
            {item.name || '-'}
        </Link>),
        sortingField: 'name',
    },
    {
        id: 'friendlyName',
        header: 'Friendly Name',
        cell: (item: Asset) => (<Link onFollow={() => {}}>
            {item.friendlyName || '-'}
        </Link>),
        sortingField: 'friendlyName',
    },
    {
        id: 'deviceState',
        header: 'State',
        cell: (item: Asset) =>(<DeviceStateBadge state={item.deviceState}/>),
        sortingField: 'deviceState',
    },
    {
        id: 'deviceTypeId',
        header: 'Device Type',
        cell: (item: Asset) => item.deviceTypeId,
        sortingField: 'deviceTypeId',
    },
    {
        id: 'physicalDeviceId',
        header: 'Physical Device ID',
        cell: (item: Asset) => item.physicalDeviceId,
        sortingField: 'physicalDeviceId',
    },
    {
        id: 'description',
        header: 'Description',
        cell: (item: Asset) => item.description,
        sortingField: 'description',
    },
    {
        id: 'lookupId',
        header: 'Lookup ID',
        cell: (item: Asset) => item.lookupId,
        sortingField: 'lookupId',
    },
    {
        id: 'invoiceId',
        header: 'Invoice ID',
        cell: (item: Asset) => item.invoiceId,
        sortingField: 'invoiceId',
    },
    {
        id: 'friendlyCurrentPath',
        header: 'Friendly Current Path',
        cell: (item: Asset) => item.friendlyCurrentPath,
        sortingField: 'friendlyCurrentPath',
    },
    {
        id: 'lastUpdated',
        header: 'Last Updated',
        cell: (item: Asset) => {
            const date = new Date(item.updatedAt * 1000).toLocaleString();
            return date === 'Invalid Date' ? 'Not recorded' : date;
        },
        sortingField: 'lastUpdated',
    },
];

export const buttonDropdownItems = (selectedDevices: Asset[]) => {
    const isEnabledFor = (enabledStates: DeviceState[], allowsBulk: boolean = false) =>
        selectedDevices.length > 0 && selectedDevices.every(device => enabledStates.includes(device.deviceState)) && (allowsBulk || selectedDevices.length === 1);

    return [
        {
            text: 'Install',
            id: 'install',
            disabled: !isEnabledFor([DeviceState.disabled, DeviceState.inService], true),
        },
        {
            text: 'Update',
            id: 'edit',
            disabled: !isEnabledFor([DeviceState.disabled, DeviceState.inService], false),
        },
        {
            text: 'Disable',
            id: 'disable',
            disabled: !isEnabledFor([DeviceState.inService], true),
        },
        {
            text: 'Delete',
            id: 'delete',
            disabled: !isEnabledFor([DeviceState.disabled, DeviceState.inService], false),
        },
    ];
};

